@import "../../scss/config";

.banner-wrapper{
    width: 100%;
    margin: 4rem 0 2rem 0;

  }
.btn-fan{
    background-color: $theme-color;
    border-color: $theme-color;
    &:hover{
        background-color: $theme-hover;
        border-color: $theme-hover;
    }
    &:focus{
        background-color: $theme-hover;
        border-color: $theme-hover;
    }
    &:disabled{
        background-color: $black;
        border-color: $black;
        // color: black !important;
    }
}
.bio-sub-menu{
    margin: auto;
    font-size: $font-m;
    font-family: 'X-Heighting';
    color: $black;
  //   overflow-wrap: break-word;
    flex-wrap: wrap;
    ul{
        margin: auto;
        li{
            display: inline;
            margin-right: 20px;
        }
    }
}

.bio-profile-pic{
    width: 70px;
    height: 70px;
    border-radius: 35px;
}
.bio-creator-name{
    font-size: $font-l;
    font-weight: 600;
    color: $black;
    // margin-left: 20px;
}
.center-btn{
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
}
.bio-sub-nav{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    a{
        margin-right: 1.5rem;
    }
    a.active{
        color: $theme-hover !important;
        cursor: auto;
    }
}

@media (min-width: $md){
    .bio-sub-nav{
        margin-top: 0;
    }
    
}