// Color palette
//-----------------------------
$theme-color: #6E4DD5;
$theme-darker: #2D1468;
$theme-lighter: rgba(110, 77, 213, 0.1);
$theme-ultlalight: rgba(110, 77, 213, 0.05);
$theme-alpha-50: rgba(110, 77, 213, 0.5);
$primary-color-light: rgba(98,0,238,0.12);
$highlight-color: #7110BD;
$theme-hover: rgba(45, 20, 104, 0.5);
$tertiary-color: white;
$black: #222;
$gray: #555;
$secondary: 'teal';
// Width setting

$main-width-pc: 1200px;
$main-width-tablet: 1000px; 
$main-width-phone: 900px; 

// bootstrap breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

//font-size
$font-xs: 13px;
$font-s: 14px;
$font-m: 16px;
$font-l: 22px;

$font-title: 'Best in Class';
$font-general: 'MerriweatherSans', sans-serif;
$font-nav: 'X-Heighting', sans-serif;