@import "../../scss/config";

.social-text{
    display: inline;
    margin-left: 1rem;
}
.social-media-container{
    margin-bottom: 2rem;
}
.social-img{
    width: 70px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 10px;
}