@import "../scss/config";

.notice-div{
    background-color: $theme-color;
    color: $tertiary-color;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: $font-general;
}
.giveaway-card{
    font-family: $font-general;
    img{
        object-fit: cover;
    }
}
.counter-div{
    background: $primary-color-light;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: -20px;
}
.counter-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 20px 3rem 0 3rem;
}
.counter-box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.padding-btn{
    padding: 20px;
}
.timer-num{
    font-size: 2rem;
    color: $theme-darker;
}
.ends-in{
    display: flex;
    align-items: center;
    color: $theme-darker;
}
.share-icon{
    width: 20px;
    height: auto;
    margin-left: 10px;
}
.social-share-btn{
    display: inline;
    float: right;
}
.card-title{
    display: inline;
}
.share-btn-wrapper{
    float: right;
}
// .card-title-text{

// }
.card-left{
    float: left;
}
.link-counter{
    display: inline;
}
.unit-div{
    color: $theme-hover;
    font-size: small;
}
.card-mute-text{
    color: $gray;
    margin-left: 1rem;
}
.progress-bar-wrapper{
    width: calc(100% - 100px);
    border: 1px solid #ccc;
    height: auto;
    margin: 0 auto;
    background: #ccc;
    border-radius: 2px;
}
.progress-bar-smoke{
    height: 10px
}
.padding-bar{
    padding: 0 20px 0 20px;
}
.start{
    position: relative;
    top: 3.6rem;
    font-size: $font-xs;
}
.end{
    position: relative;
    left: 90%;
    top: -2.7rem;
    font-size: $font-xs;
}
.car-wrapper{
    width: calc(100% - 100px);
    height: auto;
    margin: auto;
}
.bar-smoke{
    background: $theme-color;
    height: 8px;
    border-radius: 2px;
}
.dot-bar{
    width: 16px;
    height: 16px;
    background-color: $theme-color;
    border-radius: 8px;
    position: relative;
    top: 13px;

}
.car-icon{
    position: relative;
    top: 17px;
    height: 1.3rem;

}
.flag-wrapper{
    position: relative;
    top: -3.2rem;
    left: 90%;
}
.flag-icon{
    width: 2rem;
    height: auto;
}

@media (min-width: 1400px){
    .end{
        left: 92%;
    }
}