.logo-header{
  width: 120px;
  height: auto;
  margin-left: 15px;
}

@media (min-width: 576px) {
  .logo-header{
    width: 150px;
    height: auto;
  }
}