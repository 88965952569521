@import "../scss/config";

.ad-div{
    display: flex;
    justify-content: center;
    background-color: $theme-ultlalight;
}
.car-stats-wrapper{
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding-left: 0;
}
.stats-tab-ul{
    text-align: center;
    width: 100%;
    font-family: $font-general;
}
.tab-link{
    background-color: white;
    color: $black;
    padding: 8px 6px;
    &:hover{
        cursor: pointer;
    }
}
.tab-active{
    background-color: $theme-color;
    color: $tertiary-color;
}
.tab-wrapper{
    text-align: center;
}
.stats-div{
    width: 100%;
    border-top: 4px solid $theme-color;
    padding: 20px;
}
.stats-box{
    background-color: $primary-color-light;
    border-radius: 4px;
    font-family: $font-general;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 4px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 10px 14px;
    display: inline-flex;
    line-height: 1.3rem;
}
.color-thumbnail{
    width: 26px;
    height: 26px;
    border-radius: 13px;
    margin-right: 15px;
}
.color-name{
    margin: auto;
}
.stats-box-outline{
    border: 1px solid  $primary-color-light;
    border-radius: 4px;
    font-family: $font-general;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 4px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 10px 14px;
    display: inline-flex;
    line-height: 1.3rem;
}
.stats-label{
    margin: auto;
}
.search-wrapper{
    display: flex;
    justify-content: center;
    font-family: $font-general;
    flex-direction: column;
    align-items: center;
}

.search-btn{
    background-color: #6E4DD5;
    border-color: #6E4DD5;
    color: $tertiary-color;

    box-shadow: 0 0 5px #ccc;
    padding: 7px 15px;
    min-width: 150px;
    &:hover{
        background-color: $theme-hover;
        border-color: $theme-hover;
    }
}
.form-group{
    margin-bottom: 0 !important;
}
.center-box{
    margin-top: 4rem;
    // margin-bottom: auto;
}
.form-group-search{
    border-radius: 0;
    
}
.dropdown-menu {
    overflow: scroll;
    height: 44vh !important;
}
#dropdown-brand{
    padding: 7px 30px;
    background: white;
    border: 1px solid white;
    box-shadow: 0 0 5px #ccc;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: #222;
    border-color: #ccc;
    min-width: 150px;
}
#dropdown-model, #dropdown-year, #dropdown-type{
    padding: 7px 30px;
    background: white;
    border: 1px solid white;
    box-shadow: 0 0 5px #ccc;
    border-radius: 0;
    color: #222;
    border-color: #ccc;
    min-width: 150px;
}

// #dropdown-year{
//     padding: 7px 30px;
//     background: white;
//     border: 1px solid white;
//     box-shadow: 0 0 5px #ccc;
//     border-radius: 0;
//     color: #222;
//     min-width: 200px;
// }
.maker-img{
    max-width: 25px;
    max-height: 25px;
    margin-right: 15px;
}
.icon-stats{
    width: 22px;
    height: 22px;
}
.list-container{
    float: left;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-bottom: 4rem;
}
.list-pd{
    padding: 0 5rem;
    text-align: left;
}
.stats-list{
    list-style: none;
    width: max-content;
}
.xs-txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    align-items: center;
    height: 100%;
    margin-right: 1rem;
    span{
        height: 22px;
    }
}

.tab-link{
    display: block;
}

.ad-on-search{
    text-align: center;
    margin: auto;
}
.ad-pc{
    display: none;
}
.ad-mobile{
    display: block;
}
@media (min-width: $md){
    .stats-container{
        padding: 0 1rem;
    }
    .ad-pc{
        display: block;
    }
    .ad-mobile{
        display: none;
    }
    // .ad-on-search{
    //     width: 728px;
    //     height: 90px;
    // }
}

@media (min-width: $lg){
    .tab-link{
        display: table-cell;
    }
    .stats-container{
        padding: 0;
    }
    .search-wrapper{
        flex-direction: row;
        align-items: baseline;
    }
    .search-btn{
        border-bottom-left-radius:0;
        border-top-left-radius:0;
    }
}