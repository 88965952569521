.square-insta {
    position: relative;
    width: 100%;
        // padding-bottom: 100%;
        overflow: hidden;
  }
  
  .square:after {
    content: "";
    display: block;
    // padding-bottom: 100%;
    overflow: hidden;
  }
  
  // .content-insta {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
  .insta-row{
      margin: 0;
      width: 100%;
  }
  .insta-video{
    width: 100%;
    height: auto;
  }
  .page-num-insta{
    margin: auto 2rem;
  }
  .pagination-insta{
    justify-content: center;
  }