@import "./config";

@font-face {
  font-family: 'Progress';
  src: local('Progress'), url(../fonts/Progress.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Best in Class';
  src: local('BestInClass'), url(../fonts/BEST-IN-CLASS.ttf) format('truetype');
  font-display: swap;
}
@font-face{
  font-family: 'MerriweatherSans';
  src: url('../fonts/MerriweatherSans-VariableFont_wght.ttf'); 
  src: local('MerriweatherSans'), url(../fonts/MerriweatherSans-VariableFont_wght.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
}
@font-face{
  font-family: 'X-Heighting';
  font-style: normal;
  font-weight: normal;
  src: local('X-Heighting'), url(../fonts/X-Heighting400.ttf) format('truetype');
  src: url('../fonts/X-Heighting400.ttf');
  font-display: swap;
}

*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: 'MerriweatherSans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2{
  font-family: 'Best in Class', sans-serif;
}
h3{
  font-family: 'MerriweatherSans', sans-serif !important;
  font-size: $font-m !important;
  line-height: 23px;
}
h4{
  font-family: 'MerriweatherSans', sans-serif !important;
  font-size: xx-large !important;
  font-weight: 600 !important;
}
.h1-seo{
  font-size: 0rem;
}
a{
  color: $black !important;
}
a:hover{
  color: $theme-darker !important;
}
button:disabled{
  background-color: gray !important;
  border-color: gray !important;
}
// bootstrap overwrite
.row {
  margin-bottom: 1rem;
}
// text color style
.theme-text-color{
  color: $theme-darker;
}
.theme-text-p{
  font-family: $font-general;
}
header{
  width: 100vw;
  height: 115px;
  background: linear-gradient(180deg, rgba(110,77,208,1) 0%, rgba(255,255,255,1) 100%);
  margin: 0 auto;
}

.main-wrapper{
  max-width: $main-width-phone;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}
.main-col{
  margin-bottom: 30px;
}
.height-adj-nav{
  margin-top: 10%;
}
.height-adj-main{
  min-height: calc(100vh - 342px);
}
.spacer-4rem{
  margin-top: 4rem;
}
.spacer-2rem{
  margin-top: 2rem;
}
.spacer-1rem{
  margin-top: 1rem;
}
.p-lr-4{
  padding-right: 4px;
  padding-left: 4px;
}
.show-mobile{
  display: inline;
}
.title{
  color: #2D1468;
  border-bottom: 3px solid #2D1468;
  width: 100%;
  font-size: 2rem;
}
.btn-search{
  background-color: #2D1468;
}
.btn-spec{
  border: 1px solid $theme-color;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 5px;
  margin: 5px 0;
  background-color: $theme-ultlalight;
  color: $theme-color;
  text-align: center;
  &:hover{
    background: rgba(255,255,255,0.66);
    cursor: pointer;
  }
}
.goback-btn{
  &:hover{
    cursor: pointer;
  }
}
.tooltip-btn{
  &:hover{
    cursor: pointer;
  }
}
.min-height-all{
  min-height: calc(100vh - 21rem);
}
.form-adj{
  height: 22px;
  margin-bottom: 5px;
}
.box-shadow-white{
  box-shadow: 0 0 6px #ccc;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-title{
  min-height: 38px;
  font-weight: 600;
}
.spec-wrapper{
  width: 100%;
  background-color: $primary-color-light;
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
  // padding-left: 5px;
  // padding-bottom: 5px;
}
.spec-text{
  font-size: $font-s;
  line-height: $font-s;
  font-family: 'Arial Narrow', Arial, sans-serif;
}
.icon-s{
  width: 20px;
  height: auto;
}
.profile-pic{
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px;
}
.profile-pic > div{
  font-size: $font-m !important;
  // padding-top: 2px !important;
}
.comment-txt{
  font-weight: 100;
  font-size: $font-xs;
  font-family: "MerriweatherSans", Arial;
  color: #333;
}
.comment-username{
  font-weight: 700;
  font-size: $font-xs;
  font-family: "MerriweatherSans", Arial;
}
.comment-wrapper{
  width: 100%;
  margin: 1rem 0 !important;
  color: $black;
}
.comment-login-wrapper{
  text-align: right;
}
.comment-input{
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
}
.comment-input:focus{
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
}
.comment-btn{
  background-color: $theme-color !important;
  border: 0 !important;
  margin-top: 5px;
  font-size: $font-s !important;
  margin-right: 0;
}

label{
  margin-bottom: 0px !important;
}
.creator-name{
  font-size: $font-m;
  font-family: "MerriweatherSans", Arial;
  margin-left: 15px;
  line-height: $font-m;
}

.creator-profile-pic{
  width: 46px;
  height: 46px;
  border-radius: 23px;
}
.influencer-desc{
  height: 100px;
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5rem;
  max-height: 6rem;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
}
.influencer-banner{
    width: 100%;    
    object-fit: cover;
    height: 100px;
}
.not-found{
  font-size: 5rem;
  font-family: $font-general;
  margin: 2rem auto;
  text-align: center;
}
.go-home{
  font-size: 3rem;
  font-family: $font-general;
  margin: 3rem auto;
  text-align: center;
  &:hover{
    cursor: pointer;
  }
}
.not-found-wrapper{
  min-height: calc(100vh - 21rem);
  
}
.ad-container{
  width: 100%;
  max-width: 300px;
  height: auto;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ad-160{
  display: none;
}
.ad-300{
  display: block;
}
.parent-row{
  padding-left: -7px;
  padding-right: -7px;
  overflow: hidden;
}
.main-height{
  min-height: calc(100vh - 21rem);
}
// video styles
.acd-input {
  display: none;
}
.acd-label {
  display: block;    
  padding: 8px 0;
  margin: 0 0 1px 0;
  cursor: pointer;

  color: $theme-color;
  transition: ease .5s;
  text-decoration: underline;
  position: relative;
  top: 3rem;
}
.acd-label::after {
  content: ' more';
}
.acd-input:checked + .acd-label::after {
  content: ' less';
}
.acd-input:checked + .acd-label{
  top: 1rem;
}
.acd-label:hover {
  background: transparent;
}
.content {
  padding: 10px 0;
  margin: 0 0 1px 0;
}
.acd-input:checked + .acd-label{
  top: 1rem;
}
.acd-input + .acd-label + .desc-box + .content {
  display: none;
}
.acd-input:checked + .acd-label + .desc-box + .content {
  display: block;
  overflow-wrap: anywhere;
  .acd-wrapper:first-child & {
      background-color: white;
      margin-top: -5rem;
  }
}
.acd-input:checked + .acd-label + .desc-box{
  display: none;
}
.acd-input:checked + .acd-label + .desc-box-featured{
  display: none;
}
.desc-box{
  margin-top: -2rem;
  width: 100%;
  max-height: 2.6rem;
  overflow: hidden;
  display: block;
  font-size: 14px;
  overflow-wrap: anywhere;
  word-break: break-word;
  -webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}
.desc-box-featured{
  margin-top: -2rem;
  width: 100%;
  max-height: 2.6rem;
  overflow: hidden;
  display: block;
  font-size: 14px;
  overflow-wrap: anywhere;
  word-break: break-word;
  -webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}
.desc-box-expanded{
  width: 100%;
  margin-top: 1rem;
  overflow: visible;
  // background-color: white;
  // height: auto;
  font-size: 14px;
  background-color: aliceblue;
  font-size: 14px;
  /* height: 2.6rem; */
  position: absolute;
  z-index: 100;
  top: 22rem;
}
@media (min-width: 576px) { 
  .main-wrapper{
    padding: 0;
  }
  .spec-col{
    padding-left: 0  !important;
  }
  .spec-col-test{
    padding-left: 0  !important;
    width: 300px;
  }
  .ad-size{
    width: 160px;
  }
  .ad-container{
    height: 600px;
  }
  .ad-160{
    display: block;
  }
  .ad-300{
    display: none;
  }
  // .spec-wrapper{
  //   width: 300px;
  // }
  .main-col{
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) {
  .main-wrapper{
    padding: 0 15px;
  }
  .show-mobile{
    display: none;
  }
}
@media (min-width: 1024px){
  .main-wrapper{
    // max-width: $main-width-tablet;
    max-width: 90vw;
    padding: 0;
  }

}
@media (min-width: 1250px){
  
  .main-wrapper{
    // max-width: $main-width-pc;
    max-width: 90vw;
  }
}

@media (min-width: 1500px){
  .ad-size{
    width: 300px;
  }
  .ad-container{
    min-height: 250px;
  }
  .ad-160{
    display: none;
  }
  .ad-300{
    display: block;
  }
}